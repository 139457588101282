<template>
    <div >
        <!--        导航-->
        <div>
            <rxNavBar title="智能设备" androidOrIOSFlag="true"></rxNavBar>
        </div>
      <van-pull-refresh v-model="isLoading" success-text="刷新成功" @refresh="onRefresh">
        <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="noContent ? '' : '已经到底了'"
            @load="onLoad"
        >
          <div v-for="(item,index) in myDeviceList" :key="index" class="Device-One"  >
            <div class="address">
              <div class="houseStatus" :class="{'bg-1':item.contractStatus==6,'bg-2':item.contractStatus==1,'bg-3':item.contractStatus==3}">{{item.contractStatusStr}}</div>
              <div class="addressText">{{item.houseAddress}}</div>
            </div>
            <div class="details">
              <div class="detail1"><span>合同编号:   </span>{{item.contractCode}}</div>
              <div class="detail2"><span>租期:   </span>{{item.renterContractBeginTime + '至' +item.renterContractEndTime}}</div>
            </div>
            <div class="devices"  v-if="item.publicEquipment && item.publicEquipment.length > 0" >
              <div v-for="(device,index) in item.publicEquipment" :key="index">
                <div class="device2" v-if="device.equipmentType != undefined && '1' === device.equipmentType" @click="electricmeter(item,index)">
                  <img class="deviceImg" :src="require('../../../assets/images/doorLock.png')"/>
                  <div class="deviceName">智能电表</div>
                </div>
                <div class="device" v-if="device.equipmentType != undefined && '0' === device.equipmentType && index == 0" @click="doorLock(item,index)">
                  <img class="deviceImg" :src="require('../../../assets/images/electricmeter.png')"/>
                  <div class="deviceName">智能门锁</div>
                </div>
              </div>
            </div>

            <div class="devices2"  v-else>
              <span class="device2Test">该屋暂未安装智能家居设备</span>
            </div>
          </div>
          <common-empty v-if="noContent"></common-empty>
        </van-list>
      </van-pull-refresh>

    </div>
</template>

<script>
import {
  List,
  NavBar, Popup, PullRefresh
} from 'vant'
    // import {} from "../../getData/getData";
    // import {responseUtil} from "../../libs/rongxunUtil";
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    import {getStaffId, globaluserId, responseUtil} from "../../../libs/rongxunUtil";
    import {intelligentDeviceList} from "../../../getData/getData";
    export default {
        components:{
            [NavBar .name]:NavBar,
          [PullRefresh.name]: PullRefresh,
          [Popup.name]: Popup,
          [List.name]: List,
            rxNavBar,
        },
        data(){
            return{
              isLoading: false,
              loading: false,
              finished: false,
              noContent: false,
              page: 1,
              myDeviceList: []

            }
        },
        created() {
        },
        methods:{
          onRefresh() {
            this.myDeviceList = [];
            setTimeout(()=>{
              this.page = 1;
              this.finished = false
              this.loading = true
              this.noContent = false
              this.isLoading = false
              this.onLoad();
            },500)

          },
          //下拉加载
          onLoad: function () {
            var that = this
            let initData = {}
            //initData.user_id = getStaffId()
            initData.user_id = globaluserId()
            //下拉刷新的页数
            initData.currentPage = that.page
            initData.numberPage = 3
            intelligentDeviceList(initData).then(function (response) {
              responseUtil.dealResponse(that, response, () => {
                console.log(response)
                let count = response.data.data.totalPage
                let list = response.data.data.data
                if (list)
                  for (let i = 0; i < list.length; i++){
                    let deviceList = list[i].publicEquipment
                    if(deviceList != undefined && deviceList.length > 0){
                        let publicEquipment = [];
                        for(let x = 0; x < deviceList.length; x++){
                          publicEquipment.push(deviceList[x])
                          if(deviceList[x].equipmentType == 1){
                            break;
                          }
                        }
                        list[i].publicEquipment = publicEquipment;
                    }
                    that.myDeviceList.push(list[i])

                  }
                // 加载状态结束
                that.loading = false;
                // 数据全部加载完成
                //debugger
                if (!list || !list.length || that.myDeviceList.length >= count) {
                  that.finished = true;
                  //console.log(that.finished)
                }
                console.log(that.page)
                console.log(list.length)
                if (1 == that.page && (!list || 0 == list.length)) {
                  that.noContent = true
                } else {
                  that.noContent = false
                  that.page++;
                }
              })
            })
          },

            //智能电表
            electricmeter(item,index) {
            debugger
              this.$router.push({
                  name : 'electricmeter',
                  query : {
                      roomHouse_id:item.roomHouse_id,
                      setHouse_id:item.setHouse_id,
                      houseAddress : item.houseAddress,
                      uuid:item.publicEquipment[index].uuid
                  }
              })
            },

            //智能门锁
            doorLock(item) {
                this.$router.push({
                    name : 'doorLock',
                    query : {
                        roomHouse_id:item.roomHouse_id,
                        setHouse_id:item.setHouse_id,
                        houseAddress : item.houseAddress,
                        uuid : item.publicEquipment[0].uuid,
                        equipment_id:item.publicEquipment[0].id
                    }
                })
            },


            leftReturn(){
                this.$router.go(-1)
            },



        }
    }

</script>

<style scoped>
.deviceName{
  position: relative;
  top: -46px;
  left: 14px;
}
.deviceImg{
  height: 35px;
  width:45px;
  position: relative;
  left: -39px;
}
.device{
  width: 120px;
  height: 35px;
  border-radius: 7px;
  background: linear-gradient(to left,rgba(253, 58, 132, 1),rgba(255, 166, 141, 1));
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  left: 11px;
  top: 15px;
  float: left;
  font-size: 15px;
  text-align: center;
  line-height: 35px;
  font-family: PingFangSC-Semibold;
  color: rgba(255, 255, 255, 1);
  margin-right: 10px;
}
.device2{
  width: 120px;
  height: 35px;
  border-radius: 7px;
  background: linear-gradient(to left,rgba(73, 130, 223, 1),rgba(104, 175, 255, 1));
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  left: 11px;
  top: 15px;
  float: left;
  font-size: 15px;
  text-align: center;
  line-height: 35px;
  font-family: PingFangSC-Semibold;
  color: rgba(255, 255, 255, 1);
  margin-right: 10px;

}
.houseStatus{
  width: 43px;
  height: 16px;
  color: rgba(255, 255, 255, 1);
  font-size: 10px;
  text-align: left;
  font-family: PingFangSC-Semibold;
  background: linear-gradient(to right,rgba(102, 203, 148, 1), #28D3B0 100%);
  text-align: center;
  line-height: 16px;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
  line-height: 16px;
}
.bg-1{
  background: linear-gradient(to right,#F5346B, #FB6869);
}
.bg-2{
  background: linear-gradient(to right,#FFBC6F, #FE683E);
}
.bg-3{
  background: linear-gradient(to right,#d7ff99, #adff2f);
}
.Device-One{
  height: auto;
  width: 345px;
  margin-left: 15px;
  position: relative;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 15px;
}
.address{
  height: 65px;
  width: 100%;

  border-bottom: 1px solid rgba(239, 239, 239, 1);
}
.addressText{
  height: 21px;
  width: 310px;
  font-size: 15px;
  margin-left: 15px;
  background-color: white;
  text-align: left;
  line-height: 21px;
  padding-top: 10px;
  color: rgba(34, 34, 34, 1);
  text-align: left;
  font-family: PingFangSC-Semibold;
}
.details{
  width: 100%;
  height: 72px;
}
.detail1{
  left: 35px;
  top: 162px;
  width: 144px;
  height: 18px;
  color: rgba(153, 153, 153, 1);
  font-size: 13px;
  text-align: left;
  font-family: PingFangSC-Regular;
  margin-left: 15px;
  padding-top: 11px;
}
.detail2{
  left: 35px;
  top: 190px;
  width: 217px;
  height: 18px;
  color: rgba(153, 153, 153, 1);
  font-size: 13px;
  text-align: left;
  font-family: PingFangSC-Regular;
  margin-left: 15px;
  margin-top: 6px;
}
.devices{
  height: 65px;
  width: 100%;
  background-color: white;
  border-top: 1px solid rgba(239, 239, 239, 1);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.devices2{
  height: 43px;
  width: 100%;
  border-top: 1px solid rgba(239, 239, 239, 1);
  font-size: 13px;
  font-family: PingFangSC-Semibold;
  line-height: 40px;
}
.device2Test{
  position: relative;
  left: 11px;
}

</style>


